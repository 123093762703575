import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

export type TableRowData = {
	name: string;
	value: string;
};

export type View = {
	name: string;
	dataSource: (...args: any[]) => Promise<TableRowData[]>;
	params: any;
};

// Sometimes the API returns no metrics if no data has been logged in that timeframe yet.
// This is a crude way to handle that case by hardcoding the data to show. Is NOT resilient to new metrics.
const EmptyMetricData: TableRowData[] = [
	{ name: 'Aircraft Interactions', value: '0' },
	{ name: 'Miles Driven', value: '0 mi' },
	{ name: 'Number Of Conveyances', value: '0' },
	{ name: 'Total Runs', value: '0' },
	{ name: 'Unique Aircraft Seen', value: '0' },
];

interface MultiViewTableProps {
	title: string;
	views: View[];
}

const MultiViewTable: React.FC<MultiViewTableProps> = ({
	title,
	views,
}: MultiViewTableProps) => {
	const [activeViewIndex, setActiveViewIndex] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [data, setData] = useState<TableRowData[]>([]);
	const [error, setError] = useState<string>('');
	const { getAccessTokenSilently } = useAuth0();

	const fetchDataFromActiveDataSource: () => {} = async () => {
		setIsLoading(true);
		try {
			const dataSourceFunction = views[activeViewIndex].dataSource;
			const params = views[activeViewIndex].params;
			const token = await getAccessTokenSilently();
			const newData = await dataSourceFunction(token, ...params);
			setData(newData);
			setError('');
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				if (error.code === 'ERR_BAD_REQUEST') {
					setData(EmptyMetricData);
				}
			} else {
				setError(
					'An unknown error occured while fetching data. Please try again in a little bit.',
				);
				console.error(`Failed to fetch data: ${error}`);
			}
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchDataFromActiveDataSource();
	}, [activeViewIndex]);

	return (
		<Container>
			<TableHeader>
				<Title>{title}</Title>
				<Views>
					{views.map((view, index) => (
						<View
							isActive={activeViewIndex === index}
							key={index}
							onClick={() => setActiveViewIndex(index)}
						>
							{view.name}
						</View>
					))}
				</Views>
			</TableHeader>
			<TableBodyContainer>
				{error ? (
					<div>{error}</div>
				) : (
					<TableBody>
						{data.map((tableRowData, index) => (
							<TableRow key={index}>
								<Name>{tableRowData.name}</Name>
								<Value>
									{isLoading ? (
										<CircularProgress size={16} />
									) : (
										tableRowData.value
									)}
								</Value>
							</TableRow>
						))}
					</TableBody>
				)}
			</TableBodyContainer>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const TableHeader = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	padding-bottom: 0px;
	gap: 20px;
	border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const Title = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
	font-size: 20px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
`;

const Views = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
`;

const View = styled.span<{ isActive: boolean }>`
	padding-bottom: 10px;
	border-bottom: ${(props) =>
		props.isActive ? `3px solid ${props.theme.colors.navyBlue}` : 'none'};
	color: ${(props) =>
		props.isActive ? props.theme.colors.navyBlue : props.theme.colors.grey};
	font-size: 16px;
	font-weight: ${(props) => props.theme.fontWeight.medium};
	cursor: pointer;

	&:hover {
		border-bottom: ${(props) =>
			props.isActive
				? `3px solid ${props.theme.colors.navyBlue}`
				: `3px solid ${props.theme.colors.grey}`};
	}
`;

const TableBodyContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 80px;
`;

const TableBody = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 1px;
	min-height: 50px;
`;

const TableRow = styled.div`
	display: flex;
	flex-direction: row;
	padding: 10px 20px;
	align-items: center;
	justify-content: space-between;
	border: 1px solid ${(props) => props.theme.colors.lightGrey};
	margin: -1px -1px;
`;

const Name = styled.span`
	font-size: 16px;
`;

const Value = styled.span`
	font-size: 18px;
	font-weight: ${(props) => props.theme.fontWeight.medium};
`;

export default MultiViewTable;
